<template>
  <div class="resubmit">
    <van-cell-group inset>
      <!-- <van-cell title="选择街道" :value="villageSelect.streetName" is-link />
      <van-cell title="选择社区" :value="villageSelect.communityName" is-link />
      <van-cell title="选择小区" :value="villageSelect.villageName" is-link />
      <van-row class="col-cell">
        <van-col :span="8"><van-cell :title="villageSelect.buildingName || '选择楼栋'" is-link /></van-col>
        <van-col :span="8"><van-cell :title="villageSelect.unitName || '选择单元'" is-link /></van-col>
        <van-col :span="8"><van-cell :title="villageSelect.houseName || '选择户室'" is-link /></van-col>
      </van-row> -->
      <van-field :value="villageSelect.streetName ? `${villageSelect.streetName}/${villageSelect.communityName}/${villageSelect.villageName}/${villageSelect.buildingName}/${villageSelect.unitName}/${villageSelect.houseName}` : ''" label="小区户室" placeholder="请选择小区户室" required @click="$refs.select.show()" is-link />
    <!-- </van-cell-group>
    <van-cell-group inset> -->
      <van-field v-model="addInfo.realName" label="姓名" placeholder="请输入姓名" required />
      <van-field v-model="addInfo.mobile" label="手机号" type="tel" placeholder="请输入手机号" required />
      <van-field v-model="addInfo.captcha" label="验证码" type="tel" placeholder="请输入验证码" required>
        <template #button>
          <van-button size="small" type="info" @click="sendCode()" :disabled="!!count || !addInfo.mobile">{{ count ? (count + '后重新获取') : '发送验证码'}}</van-button>
        </template>
      </van-field>
    </van-cell-group>
    <div style="padding: 10px">
      <van-button type="info" round block @click="submit()">提交</van-button>
    </div>
    <HouseSelect ref="select" v-model="villageSelect" :level="6" />
  </div>
</template>
<script>
import HouseSelect from '@/components/HouseSelect'
export default {
  components: { HouseSelect },
  data() {
    return {
      addInfo: {},
      villageSelect: {},
      count: 0,
      timer: null
    }
  },
  methods: {
    sendCode() {
      this.$http.get('/village/household/send', {
        params: {
          mobile: this.addInfo.mobile
        }
      }).then(res => {
        if (res.code === 0) {
          this.initTimer()
        }
      })
    },
    initTimer() {
      this.count = 59
      this.timer = setInterval(() => {
        if (this.count) {
          this.count --
        }
      }, 1000)
    },
    submit() {
      if (!this.villageSelect.streetName) {
        this.$toast('请选择户室小区！')
        return
      }
      if (!this.addInfo.realName) {
        this.$toast('请输入真实姓名！')
        return
      }
      if (!this.addInfo.mobile) {
        this.$toast('请输入手机号！')
        return
      }
      if (!this.addInfo.captcha) {
        this.$toast('请输入验证码！')
        return
      }
      this.$http.post('/village/supplecard/clientSave', { ...this.addInfo, ...this.villageSelect }).then(res => {
        if (res.code === 0) {
          this.$toast('提交成功！')
          setTimeout(() => {
            window.history.back(-1)
          }, 1000);
        }
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>
<style lang="less" scoped>
.resubmit {
  min-height: 100vh;
  background-color: #FAF7FA;
}
.col-cell {
  .van-cell__value {
    display: none;
  }
}
</style>