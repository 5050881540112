<template>
  <van-popup v-model="showPopup" round position="bottom">
    <van-cascader
      v-model="cascaderValue"
      title="请选择户室小区"
      active-color="#1989fa"
      :options="options"
      :field-names="{ text: 'name', value: 'value' }"
      @close="showPopup = false"
      @change="onChange"
    />
  </van-popup>
</template>
<script>
export default {
  name: 'VillageSelect',
  props: {
    level: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      showPopup: false,
      cascaderValue: '',
      options: [],
      urlArr: ['/subdistrict/page', '/subdistrict/page', '/village/household/buildingNo', '/village/household/unitNo', '/village/household/houseNumber']
    }
  },
  created() {
    this.$http.get('/subdistrict/page', {
      params: { parentId: 0 }
    }).then(res => {
      if (res.code === 0) {
        this.options = res.data.list.map(item => {
          item.value = item.id
          return item
        })
      }
    })
  },
  methods: {
    show() {
      this.showPopup = true
    },
    onChange({ selectedOptions, tabIndex, value }) {
      if (this.level - tabIndex <= 1) {
        this.showPopup = false
        let obj = {}
        selectedOptions.forEach((item, i) => {
          switch (i) {
            case 0:
              obj.streetId = item.id
              obj.streetName = item.name
              break;
            case 1:
              obj.communityId = item.id
              obj.communityName = item.name
              break;
            case 2:
              obj.villageId = item.id
              obj.villageName = item.name
              break;
            case 3:
              obj.buildingNo = item.id
              obj.buildingName = item.name
              break;
            case 4:
              obj.unitNo = item.id
              obj.unitName = item.name
              break;
            case 5:
              obj.houseNumber = item.id
              obj.houseName = item.name
              break;
            default:
              break;
          }
        })
        this.$emit('input', obj)
        this.$emit('change', obj)
      } else {
        let params = {}
        if (tabIndex < 2) {
          params.parentId = value
        }
        if (tabIndex >= 2) {
          params.villageId = selectedOptions[2].id
        }
        if (tabIndex >= 3) {
          params.buildingNo = selectedOptions[3].id
        }
        if (tabIndex >= 4) {
          params.unitNo = selectedOptions[4].id
        }
        this.$http.get(this.urlArr[tabIndex], {
          params: params
        }).then(res => {
          if (res.code === 0) {
            let setData = []
            if (tabIndex < 2) {
              setData = res.data.list.map(item => {
                item.value = item.id
                item.children = []
                return item
              })
            } else if (tabIndex === 2) {
              setData = res.data.map(item => {
                return {
                  name: item.buildingNo + '幢',
                  id: item.buildingNo,
                  value: selectedOptions[tabIndex].value + 'dong' + item.buildingNo,
                }
              })
            } else if (tabIndex === 3) {
              setData = res.data.map(item => {
                return {
                  name: item.unitNo + '单元',
                  id: item.unitNo,
                  value: selectedOptions[tabIndex].value + 'danyuan' + item.unitNo,
                }
              })
            } else if (tabIndex === 4) {
              setData = res.data.map(item => {
                return {
                  name: item.houseNumber + '户室',
                  id: item.houseNumber,
                  value: selectedOptions[tabIndex].value + 'hushi' + item.houseNumber,
                }
              })
            }
            this.$set(selectedOptions[tabIndex], 'children', setData)
          }
        })
      }
    }
  }
}
</script>